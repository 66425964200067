<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text class="d-flex">
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
      >
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-row :class="option === 1 ? 'justify-start align-start' : 'justify-center align-center'">
              <v-col
                cols="12"
                md="7"
                class="text-center"
              >
                <v-avatar
                  rounded
                  size="120"
                  class="mx-auto"
                  width="150"
                  height="150"
                  color="primary"
                >
                  <v-img
                    v-if="imageData || dataComputed.logo"
                    :src="imageData ? urlImage : dataComputed.logo"
                  ></v-img>
                  <span
                    v-else
                    class="font-weight-medium"
                  >{{ t('facilities.logo') }}</span>
                </v-avatar>
              </v-col>

              <v-col
                cols="12"
                md="5"
              >
                <!-- upload photo -->
                <div class="my-auto">
                  <v-file-input
                    v-model="imageData"
                    accept="image/png, image/jpg, image/jpeg"
                    :placeholder="t('facilities.select_avatar')"
                    :label="t('facilities.logo')"
                    :prepend-icon="icons.mdiCamera"
                    outlined
                    dense
                    hide-details
                    @change="onFileSelected($event)"
                  />
                </div>
                <div
                  v-if="option === 1"
                  class="my-auto"
                >
                  <v-radio-group
                    v-model="radioGroup"
                    @change="onRadioSelected($event)"
                  >
                    <v-radio
                      :key="1"
                      :label="t('facilities.create_group')"
                      :value="1"
                      color="secondary"
                    ></v-radio>
                    <v-radio
                      :key="2"
                      :label="t('facilities.add_to_group')"
                      :value="2"
                      color="secondary"
                    ></v-radio>
                  </v-radio-group>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            md="6"
            cols="12"
            :class="option === 1 ? 'mb-auto' : 'my-auto'"
          >
            <v-row>
              <v-col>
                <v-select
                  v-model="dataComputed.sports_ids"
                  :label="t('sports.sports')"
                  :items="sportsOptions"
                  item-text="name"
                  item-value="id"
                  :menu-props="{ maxHeight: '400' }"
                  multiple
                  persistent-hint
                  dense
                  outlined
                  class="mb-0"
                  :append-icon="option === 2 ? null : icons.mdiMenuDown"
                  :readonly="option===2"
                  :rules="[required]"
                >
                  <template #selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      label
                      dense
                      :input-value="data.selected"
                      @click="data.select"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <v-row
              v-if="radioGroup === 1 && option === 1"
            >
              <v-col
                md="3"
                cols="12"
              >
                <v-text-field
                  v-model="dataComputed.group_name"
                  :label="`${t('organizations.organization')} ${t('users.name')}`"
                  dense
                  outlined
                  :readonly="option===2"
                  :rules="[required]"
                ></v-text-field>
              </v-col>

              <v-col
                md="3"
                cols="12"
              >
                <v-text-field
                  v-model="dataComputed.group_email"
                  :label="`${t('organizations.organization')} ${t('users.email')}`"
                  dense
                  outlined
                  :readonly="option===2"
                  :rules="[required]"
                ></v-text-field>
              </v-col>

              <v-col
                md="6"
                cols="12"
              >
                <div class="d-flex justify-space-between">
                  <v-autocomplete
                    v-model="phoneCodeGroup"
                    dense
                    outlined
                    :items="phoneCodeOptions"
                    item-text="text"
                    item-value="value"
                    style="
                      width: 35%;
                    "
                    :append-icon="option===2 ? null : icons.mdiMenuDown"
                    :readonly="option===2"
                    hide-details
                    :rules="[required]"
                    @change="() => phoneNumberGroup = null"
                  >
                    <template v-slot:item="{ item }">
                      <img
                        :src="item.image"
                        :alt="item.value"
                        width="20"
                        height="auto"
                        class="mr-2"
                      >
                      {{ item.text }}
                    </template>
                  </v-autocomplete>

                  <v-text-field
                    v-model="phoneNumberGroup"
                    :label="`${t('organizations.organization')} ${t('users.phone')}`"
                    dense
                    outlined
                    :disabled="!phoneCodeGroup"
                    :readonly="option===2"
                    class="pl-1"
                    :rules="[required, validatePhone(phoneNumberGroup, phoneCodeGroup, 'group')]"
                    @input="e => {
                      if (!e) dataComputed.group_phone = null
                    }"
                  ></v-text-field>
                </div>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="dataComputed.group_billing_cycle"
                  dense
                  outlined
                  :label="t('facilities.billing_cycle')"
                  :items="billingCycleOptions"
                  item-text="text"
                  item-value="value"
                  :append-icon="option===2 ? null : icons.mdiMenuDown"
                  :readonly="option===2"
                  :rules="[required]"
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model.number="dataComputed.group_comission"
                  :label="t('video_details.comission')"
                  dense
                  outlined
                  prefix="%"
                  :readonly="option===2"
                  :rules="[required, integerValidator, between(dataComputed.group_comission, 0, 100)]"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="5"
              >
                <v-text-field
                  v-model.number="dataComputed.group_min_orders_amount"
                  :label="t('groups.min_orders_amount')"
                  dense
                  outlined
                  prefix="$"
                  :readonly="option===2"
                  :rules="[required, regexValidator(dataComputed.group_min_orders_amount, '^[0-9]{0,9}(\.?[0-9]{0,2})?$', 'Incorrect format')]"
                  @input="e => {
                    if (!charUnique(e, '.')) {
                      dataComputed.group_min_orders_amount = null
                    }
                  }"
                  @keypress="onlyNumberDot"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="2"
                class="text-center align-center"
                align-self="center"
              >
                <p>{{ t('filter_by') }}</p>
              </v-col>

              <v-col
                cols="12"
                md="5"
              >
                <v-select
                  v-model="dataComputed.group_min_orders_filter"
                  dense
                  outlined
                  :label="t('groups.min_orders_filter')"
                  :items="minOrdersFilterOptions"
                  item-text="text"
                  item-value="value"
                  :append-icon="option===2 ? null : icons.mdiMenuDown"
                  :readonly="option===2"
                  :rules="[required]"
                ></v-select>
              </v-col>
            </v-row>

            <v-row v-if="(option === 1 && radioGroup === 2) || option !== 1">
              <v-col>
                <v-select
                  v-model="dataComputed.groups_ids"
                  :label="t('organizations.organization')"
                  :items="groupsOptions"
                  item-text="name"
                  item-value="id"
                  :menu-props="{ maxHeight: '400' }"
                  multiple
                  persistent-hint
                  dense
                  outlined
                  class="mb-0"
                  :append-icon="option===2 ? null : icons.mdiMenuDown"
                  :readonly="option===2"
                  :rules="[required]"
                >
                  <template #selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      label
                      dense
                      :input-value="data.selected"
                      @click="data.select"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <v-row v-if="(option === 1 && radioGroup === 2) || option !== 1">
              <v-col>
                <v-select
                  v-model="dataComputed.owner_group_id"
                  :label="t('organizations.organization_owner')"
                  :items="groupsOptions"
                  item-text="name"
                  item-value="id"
                  :menu-props="{ maxHeight: '400' }"
                  persistent-hint
                  dense
                  outlined
                  class="mb-0"
                  :append-icon="option===2 ? null : icons.mdiMenuDown"
                  :readonly="option===2"
                  :rules="[required]"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            md="3"
            cols="12"
          >
            <v-text-field
              v-model="dataComputed.name"
              :label="t('users.name')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            md="3"
            cols="12"
          >
            <v-text-field
              v-model="dataComputed.short_address"
              :label="t('facilities.short_address')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            md="3"
            cols="12"
          >
            <v-autocomplete
              v-model="dataComputed.timezone"
              :label="t('facilities.timezone')"
              :items="timezonesOptions"
              dense
              outlined
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            />
          </v-col>

          <v-col
            md="3"
            cols="12"
          >
            <v-select
              v-model="dataComputed.sms_language"
              :label="t('locales.language')"
              :items="localesOptions"
              dense
              outlined
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            />
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="dataComputed.signer_name"
              :label="t('users.contact')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="dataComputed.email"
              :label="t('users.email')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required, emailValidator]"
            >
            </v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <div class="d-flex justify-space-between">
              <v-autocomplete
                v-model="phoneCode"
                dense
                outlined
                :items="phoneCodeOptions"
                item-text="text"
                item-value="value"
                style="
                  width: 35%;
                "
                :append-icon="option===2 ? null : icons.mdiMenuDown"
                :readonly="option===2"
                hide-details
                :rules="[required]"
                @change="() => phoneNumber = null"
              >
                <template v-slot:item="{ item }">
                  <img
                    :src="item.image"
                    :alt="item.value"
                    width="20"
                    height="auto"
                    class="mr-2"
                  >
                  {{ item.text }}
                </template>
              </v-autocomplete>

              <v-text-field
                v-model="phoneNumber"
                :label="`${t('organizations.organization')} ${t('users.phone')}`"
                dense
                outlined
                :disabled="!phoneCode"
                :readonly="option===2"
                class="pl-1"
                :rules="[required, validatePhone(phoneNumber, phoneCode, 'phone')]"
                @input="e => {
                  if (!e) dataComputed.phone = null
                }"
              ></v-text-field>
            </div>
          </v-col>

          <v-col
            cols="12"
            md="4"
            class="d-flex align-center"
          >
            <v-checkbox
              v-model="dataComputed.order_phone_number"
              :label="t('facilities.required_order_phone_number')"
              true-value="Y"
              false-value="N"
              color="secondary"
              dense
              class="ma-0 pa-0"
              :readonly="option===2"
              hide-details
            ></v-checkbox>
          </v-col>

          <v-col
            cols="12"
            md="4"
            class="d-flex align-center"
          >
            <v-checkbox
              v-model="dataComputed.video_declaration"
              :label="t('facilities.video_declaration')"
              true-value="Y"
              false-value="N"
              color="secondary"
              dense
              class="ma-0 pa-0"
              :readonly="option===2"
              hide-details
            ></v-checkbox>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model.number="dataComputed.cancellation_hours_qty"
              :label="t('groups.cancellation_hours_qty')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required, regexValidator(dataComputed.cancellation_hours_qty, '^[0-9]{0,9}(\.?[0-9]{0,2})?$', 'Incorrect format')]"
              @input="e => {
                if (!charUnique(e, '.')) {
                  dataComputed.cancellation_hours_qty = null
                }
              }"
              @keypress="onlyNumberDot"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model.number="dataComputed.tax_percent"
              :label="t('facilities.tax_percent')"
              dense
              outlined
              prefix="%"
              :readonly="option===2"
              :rules="[required, integerValidator, between(dataComputed.tax_percent, 0, 100)]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="dataComputed.status"
              dense
              outlined
              :label="t('status.status')"
              :items="statusOptions"
              item-text="text"
              item-value="value"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            ></v-select>
          </v-col>

          <options-buttom-tab
            :tab-number="tab"
            :is-mode-save-edit="option !== 2"
            :no-save="true"
            :info-required="true"
            :no-cancel="option === 3"
            :no-arrow="option === 2"
            @onCancel="onCancelButton"
            @onNextTab="changeTab"
          >
          </options-buttom-tab>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable no-param-reassign */
import { mdiAlertOutline, mdiCloudUploadOutline, mdiCamera, mdiMenuDown } from '@mdi/js'
import { ref, computed, onMounted } from '@vue/composition-api'
import { required, emailValidator, between, integerValidator, regexValidator, charUnique } from '@core/utils/validation'
import { info } from '@core/utils/toasted'
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'
import { onlyNumberDot, onlyNumbers } from '@core/utils/functionsHelpers'
import useCountry from '@core/utils/useCountry'
import usePhone from '@core/utils/usePhone'
import parsePhoneNumber from 'libphonenumber-js'

import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'

export default {
  components: {
    OptionsButtomTab,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
    sports: {
      type: Array,
      default: () => [],
      required: true,
    },
    groups: {
      type: Array,
      default: () => [],
      required: true,
    },
    timezones: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  setup(props, { emit }) {
    const form = ref(null)
    const radioGroup = ref(1)
    const { t } = useUtils()
    const { getCountryCode } = useCountry()
    const { phoneCodeOptions, initPhoneConfig } = usePhone()

    const dataComputed = computed(() => props.dataParams)
    const computedOption = computed(() => props.option)

    const initCode = ref('')
    const phoneCode = ref('')
    const phoneNumber = ref('')
    const phoneCodeGroup = ref('')
    const phoneNumberGroup = ref('')

    const urlImage = ref(null)
    const imageData = ref(null)

    const sportsOptions = computed(() => props.sports)
    const groupsOptions = computed(() => props.groups)
    const timezonesOptions = computed(() => props.timezones)

    const statusOptions = [
      { text: 'Active', value: 'A' },
      { text: 'Inactive', value: 'I' },
    ]

    const billingCycleOptions = computed(() => [
      { text: t('billing_cycle_types.immediately'), value: 'A' },
      { text: t('billing_cycle_types.daily'), value: 'D' },
      { text: t('billing_cycle_types.monthly'), value: 'M' },
      { text: t('billing_cycle_types.manually'), value: 'N' },
    ])

    const minOrdersFilterOptions = computed(() => [
      { text: t('organizations.organization'), value: 'G' },
      { text: t('facilities.facility'), value: 'F' },
    ])

    const localesOptions = computed(() => [
      { text: t('locales.en'), value: 'en' },
      { text: t('locales.es'), value: 'es' },
    ])

    const initialData = {
      name: null,
      short_address: null,
      signer_name: null,
      email: null,
      phone: null,
      logo: null,
      status: 'A',
      full_address: null,
      latitude: 0,
      longitude: 0,
      sports_ids: [],
      tax_percent: null,
      groups_ids: [],
      owner_group_id: null,
      group_name: null,
      group_email: null,
      group_phone: null,
      group_billing_cycle: null,
      group_comission: 0,
      group_min_orders_amount: null,
      group_min_orders_filter: 'G',
      query_place_id: null,
      order_phone_number: 'N',
      video_declaration: 'N',
      timezone: null,
    }

    const cleanImage = () => {
      imageData.value = null
      urlImage.value = null
    }

    const onFileSelected = e => {
      try {
        cleanImage()
        if (e && e.size >= 262144) info('Max size of 256K!')
        const type = e ? e.type.split('/').pop() : null
        if (e && (['jpg', 'png', 'jpeg'].includes(type)) && e.size < 262144) {
          imageData.value = e
          if (e) {
            urlImage.value = URL.createObjectURL(imageData.value)
          }

          if (imageData.value && props.option === 3) {
            dataComputed.value.imageData = imageData.value
            emit('updateEditAvatar', dataComputed.value)
          }
        } else {
          cleanImage()
        }
      } catch (error) {
        console.error('error', error)
      }
    }

    const onRadioSelected = e => {
      if (e === 1) {
        dataComputed.value.groups_ids = []
        dataComputed.value.owner_group_id = null
      } else {
        dataComputed.value.group_name = null
        dataComputed.value.group_email = null
        dataComputed.value.group_phone = null
        dataComputed.value.group_billing_cycle = null
        dataComputed.value.group_comission = 0
        dataComputed.value.group_min_orders_amount = null
        dataComputed.value.group_min_orders_filter = null
      }
    }

    const onUpdateAvatar = async () => {
      if (imageData.value) {
        emit('updateAvatar', imageData.value)
      } else {
        info('Seleccione una imagen!')
      }
    }

    const onCancelButton = () => {
      cleanImage()
      form.value.reset()
    }

    const changeTab = tab => {
      if (!dataComputed.value.logo && !imageData.value) {
        info('Seleccione una imagen!')
      }

      if (form.value.validate() && (dataComputed.value.logo || imageData.value)) {
        if (imageData.value) dataComputed.value.imageData = imageData.value

        emit('changeTab', tab, true, dataComputed.value)
      }
    }

    const onSaveAction = () => {
      if (form.value.validate()) {
        emit('save', dataComputed.value, imageData.value)
      }
    }

    const validatePhone = (e, code, phoneInput) => {
      if (code && e) {
        const phoneNumberJS = parsePhoneNumber(e, code)
        if (phoneNumberJS) {
          if (phoneNumberJS.isValid()) {
            if (phoneInput === 'group') dataComputed.value.group_phone = phoneNumberJS.number
            else dataComputed.value.phone = phoneNumberJS.number

            return true
          }
        }
        if (phoneInput === 'group') dataComputed.value.group_phone = null
        else dataComputed.value.phone = null
      }

      return 'Invalid number'
    }

    onMounted(async () => {
      if (computedOption.value === 1) {
        const countryCode = getCountryCode()
        if (countryCode) {
          const codeFinded = phoneCodeOptions.value.find(e => e.value === countryCode)
          if (codeFinded) initCode.value = countryCode
          else initCode.value = 'US'
        }
        phoneCode.value = initCode.value
        phoneCodeGroup.value = initCode.value
      }

      if (computedOption.value !== 1) {
        setTimeout(() => {
          if (dataComputed.value.phone) {
            const config = initPhoneConfig(dataComputed.value.phone)
            phoneCode.value = config.code
            phoneNumber.value = config.phone_number
          }
        }, 500)
      }
    })

    return {
      // data
      form,
      radioGroup,
      urlImage,
      imageData,
      statusOptions,
      initialData,
      sportsOptions,
      groupsOptions,
      timezonesOptions,
      billingCycleOptions,
      minOrdersFilterOptions,
      phoneCode,
      phoneNumber,
      phoneCodeGroup,
      phoneNumberGroup,

      // computed
      dataComputed,
      computedOption,
      phoneCodeOptions,
      localesOptions,

      // methods
      onCancelButton,
      changeTab,
      onSaveAction,
      avatarText,
      onFileSelected,
      onRadioSelected,
      cleanImage,
      onUpdateAvatar,
      validatePhone,

      // validations
      required,
      emailValidator,
      between,
      integerValidator,
      regexValidator,
      charUnique,
      onlyNumberDot,
      onlyNumbers,

      // i18n
      t,

      // icons
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiCamera,
        mdiMenuDown,
      },
    }
  },
}
</script>
